import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loader from 'react-loader';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Col, Form, Row } from 'react-bootstrap';
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PricingCard from './pricing-card';
import CreditCardItem from './credit-card';

import OrderSummary from './order-summary';
import PlanModal from '../../components/plan-modal';
import BillingModal from '../../components/billing-modal';
import PaymentModal from '../../components/payment-modal';
import { getPricesRequest } from '../../redux/actions/pricing.action';
import {
  getTaxRequest,
  getInvoicesRequest,
  storeSubscribeRequest,
} from '../../redux/actions/stores.action';
import { getAllCardsRequest } from '../../redux/actions/credit-card.action';

import PricingContainer from './index.style';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const Pricing = () => {
  let { storeId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [plan, setPlan] = useState({
    id: 1,
  });
  const [detailOpen, setDetailOpen] = useState(false);
  const [billInfoOpen, setBillInfoOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [annual, setAnnual] = useState(true);

  const cardsState = useSelector((state) => state.credit);
  const pricingState = useSelector((state) => state.pricing);
  const storeTax = useSelector((state) => state.stores.storeTax);
  const billAddrState = useSelector((state) => state.billing);

  const storeInvoices = useSelector((state) => state.stores.invoices);

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/pricing index.js' is in use");

  useEffect(() => {
    dispatch(getAllCardsRequest());
    dispatch(getTaxRequest(storeId));
    dispatch(getPricesRequest());
    dispatch(getInvoicesRequest(storeId));
  }, []);

  useEffect(() => {
    if (!pricingState.prices) return;

    setPlan({ id: 1, data: pricingState.prices[1] });
  }, [pricingState]);

  const togglePlan = () => setAnnual(!annual);

  const updatePlan = () => {
    if (cardsState.cards.length === 0) {
      alert('Please add a payment method.'); //TODO: Toast Message
      return;
    } else if (!billAddrState.billInfo) {
      alert('Please add Billing Information.'); //TODO: Toast Message
      return;
    }
    let cardId = cardsState.cards.filter((card) => card.isDefault === true)[0]
      .creditCardId;

    let planData = {
      subscribePeriod: annual ? 'Annual' : 'Monthly',
      creditCardId: cardId,
      priceId: plan.data.id,
      storeId: storeId,
    };

    dispatch(storeSubscribeRequest(planData));

    setTimeout(() => {
      history.push(`/stores/edit-store/${storeId}`);
    }, 2000);
  };

  return (
    <PricingContainer>
      <Loader
        loaded={pricingState ? !pricingState.loading : false}
        className="spinner"
      />

      <div className="d-flex mb-4 align-items-center justify-content-between">
        <h2 className="title">Subscription</h2>

        <div className="d-flex">
          <Form.Label className="pr-2">Monthly</Form.Label>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Annual"
            checked={annual}
            onChange={togglePlan}
          />
        </div>
      </div>

      <Container>
        <Row>
          {pricingState.prices &&
            pricingState.prices.map((table, id) => (
              <Col key={id} md="4" className="price-table">
                <PricingCard
                  data={table}
                  active={plan.id === id}
                  annual={annual}
                  onClick={() => setPlan({ id: id, data: table })}
                  openDetail={() => setDetailOpen(true)}
                />
              </Col>
            ))}
        </Row>

        <Row className="mt-5">
          <Col md="6">
            <div className="order-summary">
              <h4>Order Summary</h4>
              <hr />

              {plan.data && (
                <OrderSummary
                  annual={annual}
                  plan={plan.data}
                  storeTax={storeTax || { rate: 0 }}
                />
              )}

              <Button onClick={updatePlan}>Update Plan</Button>
            </div>
          </Col>

          <Col md="6">
            <h4>Payment Method</h4>
            <hr />
            <Loader
              loaded={cardsState ? !cardsState.loading : false}
              className="spinner"
            />
            {cardsState.cards && (
              <div className="payment-cards mb-3">
                {cardsState.cards.map((card, id) => (
                  <CreditCardItem
                    key={id}
                    card={card}
                    cardsState={cardsState}
                  />
                ))}
              </div>
            )}
            <div
              className="extra-open"
              onClick={() => setPaymentModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>Add payment method</span>
            </div>

            <div className="billing-info">
              <h4>Billing Information</h4>
              <hr />
              {billAddrState.billInfo && (
                <>
                  <div>
                    <p>
                      <b>Email:</b> {billAddrState.billInfo.email}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Phone Number:</b> {billAddrState.billInfo.phoneNumber}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Address: </b>
                      {billAddrState.billInfo.billingAddress?.streetAddress}
                    </p>
                  </div>
                  {billAddrState.billInfo.billingAddress?.additionalDetails && (
                    <div>
                      <p>
                        <b>Address Details (Suite, Unit, etc.) </b>
                        {
                          billAddrState.billInfo.billingAddress
                            ?.additionalDetails
                        }
                      </p>
                    </div>
                  )}
                </>
              )}
              <div className="extra-open" onClick={() => setBillInfoOpen(true)}>
                <FontAwesomeIcon icon={faPen} />
                <span>Update information</span>
              </div>
            </div>

            <div className="billing-history mt-5">
              <h4>Billing History</h4>
              <hr />

              {storeInvoices &&
                storeInvoices.data.map((invoice, id) => (
                  <div
                    key={id}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <a href={invoice.invoiceUrl} target="__blank">
                      <p>
                        <b>{moment(invoice.paidAt).format('MMM DD, yyyy')}</b>
                      </p>
                    </a>
                    <p>${(invoice.amountPaid / 100).toFixed(2)}</p>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </Container>

      <PlanModal
        show={detailOpen}
        plan={plan.data && plan.data}
        onClose={() => setDetailOpen(false)}
      />
      <PaymentModal
        show={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
      />
      <BillingModal
        show={billInfoOpen}
        newCard={cardsState?.cards === null}
        onClose={() => setBillInfoOpen(false)}
      />
    </PricingContainer>
  );
};

export default Pricing;
