import React, { useEffect } from 'react';
import Loader from 'react-loader';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  getStripeLoginLinkRequest,
  getStripeSetupLinkRequest,
} from '../../redux/actions/stripe-setting.action';
import PaymentMark from '../../assets/images/payment.jpg';

import PaymentsContainer from './index.style';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const Payments = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.auth);
  const stripeState = useSelector((state) => state.stripe);

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/payments index.js' is in use");

  const PaymentCard = () => (
    <Card className="payment-card">
      <img src={PaymentMark} alt="payment-img" />

      <Card.Body>
        <Card.Title>Stripe Payment Processing</Card.Title>
        <Card.Text>
          BizCoreX Inc. partners with Stripe to support payment processing. Once
          enabled you will be able to accept payments on your store and receive
          payouts.
        </Card.Text>
        <Button variant="outline-primary" onClick={gotoStripeCheckout}>
          Launch Stripe
        </Button>
      </Card.Body>
    </Card>
  );

  const getStripeSetupLink = () => {
    const params = {
      returnUrl: `${window.location.origin}/`,
      refreshUrl: `${window.location.origin}/payments`,
    };

    dispatch(getStripeSetupLinkRequest(params));
  };

  const gotoStripeCheckout = () => {
    let a = document.createElement('a');
    a.target = '_blank';
    if (profileState.profile.stripeEnabled) {
      if (!stripeState.stripeLoginLink) return;
      a.href = stripeState.stripeSetupLink.accountLink;
      //window.location.href = stripeState.stripeLoginLink.link;
    } else {
      if (!stripeState.stripeSetupLink) return;
      a.href = stripeState.stripeSetupLink.accountLink;
      //window.location.href = stripeState.stripeSetupLink.accountLink;
    }
    a.click();
  };

  useEffect(() => {
    if (!profileState.profile) return;

    if (profileState.profile.stripeEnabled) {
      dispatch(getStripeLoginLinkRequest());
    } else {
      getStripeSetupLink();
    }
  }, []);

  return (
    <PaymentsContainer>
      <Loader
        loaded={!stripeState.loading}
        className="spinner"
        color="#1f343d"
      />

      <Row>
        <Col md={6} lg={4}>
          {(stripeState.stripeSetupLink || stripeState.stripeLoginLink) && (
            <PaymentCard />
          )}
        </Col>
      </Row>
    </PaymentsContainer>
  );
};

export default Payments;
