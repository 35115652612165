import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import PlanModalContainer from './index.style';

const PlanModal = ({ plan, onClose, ...props }) => {
  return (
    <Modal
      {...props}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="plan-modal"
    >
      <PlanModalContainer>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {plan && plan.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {plan &&
              Object.keys(plan.features).map(
                (feature, id) =>
                  plan.features[feature] && (
                    <li key={id}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <span>{feature}</span>
                    </li>
                  )
              )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </PlanModalContainer>
    </Modal>
  );
};

export default PlanModal;
