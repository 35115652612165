import styled from '@emotion/styled';

const BusinessProfileContainer = styled.div`
  .company-brand-upload {
    width: 120px;
    height: 120px;
    background-color: #e1e4e8;
    border-radius: 0.5rem;
    padding: 0;
    position: relative;
    cursor: pointer;

    .custom-file-input {
      position: relative;
      width: 100%;
      height: 100%;
      outline: none;
      visibility: visible;
      cursor: pointer;

      &:before {
        content: attr(data-title);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
        overflow: hidden;
      }
    }

    svg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
      opacity: 0.65;

      svg {
        color: ${(props) => props.theme.colors.red};
      }
    }
  }

  .brand-image-list {
    .brand-image {
      img {
        border-radius: 1rem;
      }

      &:hover {
        .remove-image {
          display: flex;
        }
      }

      .remove-image {
        display: none;
        top: 0;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #dedede;
        cursor: pointer;
        color: ${(props) => props.theme.colors.red};
        opacity: 1;
        font-size: 0.75rem;
        font-weight: bold;

        &:hover {
          opacity: 0.85;
          background-color: #eee;
        }
      }
    }
  }

  .input-group-text {
    min-width: 42px;
    justify-content: center;
  }

  .form-check {
    input {
      cursor: pointer;
    }
  }
`;

export default BusinessProfileContainer;
