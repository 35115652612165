import styled from '@emotion/styled';

const EditStoreContainer = styled.div`
  .store-title {
    .dropdown {
      &-toggle {
        &:after {
          content: none;
        }
      }

      &-menu {
        min-width: 8rem;
      }
    }
  }
`;

export default EditStoreContainer;
