import styled from '@emotion/styled';

const PricingContainer = styled.div`
  .container {
    margin: 0;
    padding: 0;
    
    @media (min-width: 1024px) {
      padding-right: 15px;
    }
  }

  .price-table {
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .pricing-card {
    height: 100%;
    position: relative;
    padding: 3.5rem 1.25rem;
    border-radius: 0.25rem;
    border: 1px solid ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    transition: all 0.1s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &--selected {
      box-shadow: 0 0 15px rgba(159, 28, 37, 0.3);
      border: 1px solid ${(props) => props.theme.colors.red};
    }

    h4,
    h2 {
      font-weight: 900;c
    }

    &__description {
      flex: 1;
    }

    &__header {
      margin-bottom: 1.5rem;
    }

    &__price {
      margin-bottom: 2rem;

      h3 {
        line-height: 1.4;
        margin-right: 0.5rem;
        margin-bottom: 0;
        text-decoration: line-through;
        color: ${(props) => props.theme.colors.grey};
      }
      h2 {
        margin: 0;
      }
      span {
        color: #888;
        font-size: 14px;
        line-height: 1.3;
      }
    }

    &__radio {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.darkRed};

      .circle-inner {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.darkRed};
      }
    }
  }

  .ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #9F1C25;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 220px;
    padding: 10px 0;
    background-color: #9F1C25;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 14px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -50px;
    top: 20px;
    transform: rotate(45deg);
  }


  .order-details {
    margin-bottom: 1.5rem;
    padding: 1rem;

    @media (min-width: 1024px) {
      margin-bottom: 0;
      max-width: 450px;
    }

    span {
      text-transform: uppercase;
    }

    p {
      &.price {
        font-weight: bold;
      }
    }
  }

  .billing-info {
    margin-top: 3rem;
  }

  .extra-open {
    color: #697386;
    cursor: pointer;

    &:hover {
      color: #1a1f36;
    }

    span {
      margin-left: 0.5rem;
    }
  }

  .payment-cards {
    max-width: 400px;
    
    &__item {
      margin-bottom: 10px;

      &:last-of-type {
        margin: 0;
      }

      span {
        color: #4f566b;
        background-color: #e3e8ee;
        margin-left: 0.5rem;

        &.opacity-none {
          opacity: 0;
        }

        display: block;

        @media (min-width: 1024px) {
          display: none;
        }
      }
    }

    &__image {
      width: 50px;
      height: 30px;

      @media (min-width: 1024px) {
        width: 40px;
        height: 24px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__data {
      display: flex;
      flex-direction: column;
    }

    &__method {
      .payment-cards__expired {
        display: block;

        @media (min-width: 1024px) {
          display: none;
        }
      }

      span.badge {
        display: none;

        @media (min-width: 1024px) {
          display: block;
        }
      }
    }

    &__expired {
      display: none;
      font-size: 14px;

      @media (min-width: 1024px) {
        display: block;
        font-size: 1rem;
      }
    }

    &__number {
      font-size: 14px;

      @media (min-width: 1024px) {
        font-size: 1rem;
      }
    }

    &__action {
      margin-left: 1rem;

      &.opacity-none {
        opacity: 0;
      }

      .dropdown-toggle {
        &:after {
          content: none;
        }
      }
    }
  }
`;

export default PricingContainer;
