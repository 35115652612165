import React, { useEffect } from 'react';
import Loader from 'react-loader';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import StoreCard from '../../components/store-card';
import { getStoresRequest } from '../../redux/actions/stores.action';

import ManageStoresContainer from './index.style';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const ManageStores = () => {
  const dispatch = useDispatch();
  const storesState = useSelector((state) => state.stores);

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/stores index.js' is in use");

  useEffect(() => {
    dispatch(getStoresRequest());
  }, []);

  return (
    <ManageStoresContainer>
      <Loader
        loaded={storesState ? !storesState.loading : false}
        className="spinner"
      />

      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0">Manage My Stores</h2>
        <NavLink to={`/stores/create-store/`}>
          <Button variant="danger">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </NavLink>
      </div>

      {!storesState.loading && (
        <>
          <div className="store-list mt-5">
            <Row>
              {!storesState.loading &&
                (storesState.stores && storesState.stores?.length > 0 ? (
                  storesState.stores.map((store, id) => (
                    <Col key={id} md={6} lg={4} xl={3}>
                      <StoreCard store={store} />
                    </Col>
                  ))
                ) : (
                  <Col md={12}>
                    <h5 className="text-center">
                      You don't have any stores setup yet. Create one now!
                    </h5>
                  </Col>
                ))}
            </Row>
          </div>
        </>
      )}
    </ManageStoresContainer>
  );
};

export default ManageStores;
