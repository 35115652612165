import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const RemoveModal = ({ removeStore, onClose, ...props }) => {
  return (
    <Modal
      {...props}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this store?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          No, Keep Store
        </Button>
        <Button variant="danger" onClick={removeStore}>
          Yes, Delete Store
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveModal;
