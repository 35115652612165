const config = {
  appBaseURL: process.env.REACT_APP_BASE_URL,
  appBaseAPIKey: process.env.REACT_APP_BASE_API_KEY,
  apiBaseURL: process.env.REACT_APP_API_URL,
  publicApiBaseURL: process.env.REACT_APP_PUBLIC_API_URL,
  REACT_APP_GOOGLE_KEY: process.env.REACT_APP_GOOGLE_KEY,
  REACT_APP_GOOGLE_LIBRARIES: process.env.REACT_APP_GOOGLE_LIBRARIES.split(','),
  b2cInstance: process.env.REACT_APP_B2C_INSTANCE,
  b2cTenant: process.env.REACT_APP_B2C_TENANT,
  b2cSigninPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
  b2cAppID: process.env.REACT_APP_B2C_APPID,
  b2cScopes: process.env.REACT_APP_B2C_SCOPES.split(','),
  StripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export default config;
