import React, { useState, useEffect } from 'react';
import Loader from 'react-loader';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import AutoComplete from 'react-google-autocomplete';
import { useToasts } from 'react-toast-notifications';
import { Button, Dropdown, Form, Row, Col } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import MapWithMarker from '../../../components/map-marker';
import RemoveModal from '../../../components/remove-modal';
import {
  updateStoreRequest,
  deleteStoreRequest,
  getSingleStoreRequest,
} from '../../../redux/actions/stores.action';

import {
  getFullAddress,
  getCity,
  getCountry,
  getCountryCode,
  getState,
  getPostalCode,
  getLat,
  getLng,
  torontoPos,
  stripTrailingSlash,
} from '../../../utils/common';

import EditStoreContainer from './index.style';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const EditStore = () => {
  let { storeId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToasts();

  const [address, setAddress] = useState(null);
  const [currentPos, setCurrentPos] = useState(torontoPos);
  const [currentStore, setCurrentStore] = useState(null);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const storesState = useSelector((state) => state.stores);
  const { register, handleSubmit, reset, errors, control, formState } = useForm(
    {}
  );

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/edit-store index.js' is in use");

  useEffect(() => {
    dispatch(getSingleStoreRequest(storeId));
  }, [storeId]);

  // set default values of forms after load address API using reset from react-hook-form
  useEffect(() => {
    if (!storesState.singleStore) {
      return;
    }

    setCurrentStore(storesState.singleStore);

    const parsePhone = parsePhoneNumberFromString(
      storesState.singleStore.phoneNumber,
      storesState.singleStore.storeAddress.countryCode
    );

    reset({
      ...storesState.singleStore,
      addressDetail: storesState.singleStore.storeAddress.additionalDetails,
      phoneNumber: parsePhone.number,
    });

    setAddress(storesState.singleStore.storeAddress.streetAddress);
    setCurrentPos({
      lat: storesState.singleStore.storeAddress.latitude,
      lng: storesState.singleStore.storeAddress.longitude,
    });
  }, [storesState.singleStore]);

  useEffect(() => {
    if (storesState.error) {
      const showToast = (content) => {
        addToast(content, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      };

      showToast(storesState.error);

      gotoMainPage();

      return;
    }

    const showToast = (content) => {
      addToast(content, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    };
    setShowLoader(storesState.loading);

    if (storesState.loading || formState.submitCount === 0) return;

    showToast('Store updated successfully.');

    gotoMainPage();
  }, [storesState.loading]);

  const onSubmit = handleSubmit((data) => {
    if (!address) return;

    let storeData = {
      ...data,
      id: currentStore.storeId,
      storeAddressId: currentStore.storeAddressId,
    };

    if (currentStore.storeAddress.streetAddress === address) {
      storeData = {
        ...storeData,
        storeAddress: {
          ...currentStore.storeAddress,
          additionalDetails: data.addressDetail,
        },
      };
    } else {
      storeData = {
        ...storeData,
        storeAddress: {
          streetAddress: getFullAddress(address),
          additionalDetails: data.addressDetail,
          postalCode: getPostalCode(address),
          city: getCity(address),
          province: getState(address),
          country: getCountry(address),
          countryCode: getCountryCode(address),
          latitude: getLat(address),
          longitude: getLng(address),
        },
      };
    }

    const parsePhone = parsePhoneNumberFromString(
      data.phoneNumber,
      storeData.storeAddress.countryCode
    );

    // save phoneNumber without dialCode
    storeData = {
      ...storeData,
      phoneNumber: parsePhone.nationalNumber,
    };

    dispatch(updateStoreRequest(storeData));
    //reset({});
    reset({
      ...storeData,
      addressDetail: storeData.storeAddress.additionalDetails,
      phoneNumber: parsePhone.number,
    });
    setAddress(storeData.storeAddress.streetAddress);
  });

  const onDelete = () => {
    dispatch(deleteStoreRequest(currentStore.storeId));
    setRemoveModalShow(false);

    gotoMainPage();
  };

  const gotoMainPage = () => {
    setTimeout(() => {
      history.push('/stores');
    }, 2000);
  };

  return (
    <EditStoreContainer>
      <Loader loaded={!showLoader} className="spinner" color="#1f343d" />

      <div className="d-flex align-items-center justify-content-between mb-4 store-title">
        <h3>Manage Store</h3>

        <div className="d-flex align-items-center">
          {storesState.singleStore && !storesState.singleStore.subscription && (
            <div className="mr-3">
              <Link to={`${stripTrailingSlash(location.pathname)}/pricing/`}>
                <Button variant="danger">Subscribe Now</Button>
              </Link>
            </div>
          )}

          <Dropdown>
            <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
              More Functions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href={`${stripTrailingSlash(location.pathname)}/pricing/`}
              >
                Subscription and Billing
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">Open Back Office</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="mb-5">
        <MapWithMarker lat={currentPos.lat} lng={currentPos.lng} />
      </div>

      {storesState.singleStore && (
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupAddr">
                <Form.Label>Address</Form.Label>

                <AutoComplete
                  placeholder=""
                  onPlaceSelected={(place) => {
                    setAddress(place);
                    setCurrentPos({
                      lat: getLat(place),
                      lng: getLng(place),
                    });
                  }}
                  className={`${
                    formState.submitCount > 0 && !address && 'is-invalid'
                  } form-control`}
                  types={['address']}
                  defaultValue={address}
                />
                {formState.submitCount > 0 && !address && (
                  <Form.Control.Feedback type="invalid">
                    Address is Required
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formGroupAddressDetail">
                <Form.Label>Address Details (Suite, Unit, etc.)</Form.Label>
                <Form.Control
                  type="text"
                  name="addressDetail"
                  ref={register()}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  isInvalid={errors.name}
                  ref={register({
                    required: 'Name is Required',
                  })}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Phone Number</Form.Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Phone Number is required.' }}
                  render={({ onChange, value, name }) => (
                    <PhoneInput
                      country={
                        storesState?.singleStore &&
                        storesState?.singleStore?.storeAddress.countryCode
                      }
                      value={value}
                      inputProps={{ name }}
                      onChange={(tel) => onChange(tel)}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: errors.phoneNumber ? 'inherit' : 'none' }}
                  >
                    {errors.phoneNumber.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-3 d-flex justify-content-between align-items-center">
            <Button
              className="action-control"
              variant="primary"
              onClick={(e) => onSubmit(e)}
            >
              Update
            </Button>

            <Button
              className="action-control"
              variant="danger"
              onClick={() => setRemoveModalShow(true)}
            >
              Delete
            </Button>
          </div>
        </Form>
      )}

      {removeModalShow && (
        <RemoveModal
          show={removeModalShow}
          removeStore={onDelete}
          onClose={() => setRemoveModalShow(false)}
        />
      )}
    </EditStoreContainer>
  );
};

export default EditStore;
