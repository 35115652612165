import React, { useState, useEffect } from 'react';
import Loader from 'react-loader';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import PhoneInput from 'react-phone-input-2';
import {
  parsePhoneNumberFromString,
  isValidNumberForRegion,
} from 'libphonenumber-js';
import AutoComplete from 'react-google-autocomplete';
import { useToasts } from 'react-toast-notifications';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faEnvelope,
  faGlobe,
  faFileUpload,
  faBuilding,
  faPeopleArrows,
  faClock,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

import ImageCard from '../../components/image-card';
import config from '../../config/config';
import {
  getFullAddress,
  getCity,
  getCountry,
  getCountryCode,
  getState,
  getPostalCode,
  getLat,
  getLng,
} from '../../utils/common';

import {
  uploadBrandImageRequest,
  removeBrandImageRequest,
} from '../../redux/actions/brand-image.action';
import { updateBusinessProfileRequest } from '../../redux/actions/business-profile.action';
import BusinessProfileContainer from './index.style';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const BusinessProfile = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const companyLogo = useSelector((state) => state.brand);
  const customerProfile = useSelector((state) => state.auth.profile);
  const businessProfileState = useSelector((state) => state.business);

  const [address, setAddress] = useState('');
  const [logoImage, setLogoImage] = useState(null);
  const [subscribe, setSubscribe] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [newImageUpload, setNewImageUpload] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(false);
  const [profileToUpload, setProfileToUpload] = useState(null);
  const [businessShortName, setbusinessShortName] = useState('');
  const [waitApprove, setwaitApprove] = useState(false);
  const [timezoneList, settimezoneList] = useState([]);

  const { register, handleSubmit, reset, errors, control } = useForm({});

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/business-profile' is in use");

  useEffect(()=>{
    const ct = require('countries-and-timezones');

    const countryCA = ct.getCountry('CA');
    const countryUS = ct.getCountry('US');
    const butTimezoneList = [];
    countryCA.timezones.forEach(CAtimezone => {
      if (CAtimezone.includes("Canada")) {
        const timezone = ct.getTimezone(CAtimezone);
        butTimezoneList.push("(UTC" + timezone.utcOffsetStr + ") " + CAtimezone);  
      }
    });
    countryUS.timezones.forEach(UStimezone => {
      if (UStimezone.includes("US")) {
        const timezone = ct.getTimezone(UStimezone);
        butTimezoneList.push("(UTC" + timezone.utcOffsetStr + ") " + UStimezone);  
      }
    });
    settimezoneList(butTimezoneList);

    console.log(countryCA, countryUS, butTimezoneList);
  }, []);
  useEffect(() => {
    const showToast = (status, content) => {
      addToast(content, {
        appearance: status,
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    };
    setShowLoader(businessProfileState.loading);

    if (businessProfileState.loading || !subscribe) return;

    if (businessProfileState.error) {
      showToast(
        'error',
        `Error ${businessProfileState.error}, please check your information.`
      );
    } else showToast('success', 'Profile successfully updated.');

    setSubscribe(false);
  }, [businessProfileState.loading]);

  // Fill the form when load the page
  useEffect(() => {
    if (!customerProfile || !customerProfile.businessProfileId) return;
    let profile = customerProfile.businessProfile;
    console.log("business profile", profile);

    const parsePhone = parsePhoneNumberFromString(
      profile.phoneNumber,
      profile.businessAddress.countryCode
    );

    reset({
      businessName: profile.businessName,
      businessShortName: profile.businessShortName,
      businessDescription: profile.businessDescription,
      emailAddress: profile.emailAddress,
      website: profile.website,
      phoneNumber: parsePhone.number,
      addressDetail: profile.businessAddress.additionalDetails,
      serviceRange: profile.serviceRange,
      currency: profile.currency,
      timezone: profile.timezone,
    });
    if (profile.pendingBusinessProfile != null) {
      setwaitApprove(true);
    }
    setbusinessShortName(profile.businessShortName);
    setAddress(profile.businessAddress.streetAddress);

    if (profile.companyBrandings?.length === 0) return;

    setLogoImage({
      dataUri: `${config.publicApiBaseURL}/images/${profile.companyBrandings[0].image}`,
      fileName: profile.companyBrandings[0].image,
    });
  }, [customerProfile]);

  useEffect(() => {
    if (companyLogo.isLoading || !profileToUpload) {
      return;
    }

    let profile = profileToUpload;

    if (companyLogo.image || logoImage) {
      if (companyLogo.image) {
        profile = {
          ...profile,
          companyBrandings: [
            {
              image: companyLogo.image.fileName,
              businessProfileId: customerProfile.businessProfileId,
            },
          ],
        };
      } else if (
        customerProfile.businessProfile.companyBrandings.length > 0 &&
        logoImage?.fileName ===
          customerProfile.businessProfile.companyBrandings[0].image
      ) {
        profile = {
          ...profile,
          companyBrandings: [
            {
              image: logoImage?.fileName,
              businessProfileId: customerProfile.businessProfileId,
            },
          ],
        };
      }
    }
    if (profile.pendingBusinessProfile == null) {
      dispatch(updateBusinessProfileRequest(profile));
    }    
    reset();
  }, [companyLogo.isLoading]);

  const onSubmit = handleSubmit((data) => {
    if (!address || companyLogo.isLoading || showLoader) {
      return;
    }

    let businessAddress = {};

    if (
      customerProfile.businessProfile.businessAddress.streetAddress === address
    ) {
      businessAddress = {
        ...customerProfile.businessProfile.businessAddress,
      };
    } else {
      businessAddress = {
        streetAddress: getFullAddress(address),
        city: getCity(address),
        province: getState(address),
        country: getCountry(address),
        countryCode: getCountryCode(address),
        postalCode: getPostalCode(address),
        latitude: getLat(address),
        longitude: getLng(address),
        addressId: customerProfile.businessProfile.businessAddress.addressId,
      };
    }

    const parsePhone = parsePhoneNumberFromString(
      data.phoneNumber,
      businessAddress.countryCode
    );

    let profile = {
      ...data,
      phoneNumber: parsePhone.nationalNumber,
      businessAddressID: customerProfile.businessProfile.businessAddressID,
      businessAddress: {
        ...businessAddress,
        additionalDetails: data.addressDetail,
      },
    };

    if (companyLogo.image || logoImage) {
      if (companyLogo.image) {
        profile = {
          ...profile,
          companyBrandings: [
            {
              image: companyLogo.image.fileName,
              businessProfileId: customerProfile.businessProfileId,
            },
          ],
        };
      } else if (
        customerProfile.businessProfile.companyBrandings.length > 0 &&
        logoImage?.fileName ===
          customerProfile.businessProfile.companyBrandings[0].image
      ) {
        profile = {
          ...profile,
          companyBrandings: [
            {
              image: logoImage?.fileName,
              businessProfileId: customerProfile.businessProfileId,
            },
          ],
        };
      }
    }

    profile = {
      ...profile,
      businessProfileId: customerProfile.businessProfileId,
    };

    if (newImageUpload && logoImage) {
      dispatch(uploadBrandImageRequest(logoImage.file));
      setStartUpload(true);
      setProfileToUpload(profile);
    } else if (imageToRemove) {
      if (companyLogo && companyLogo.image) {
        dispatch(removeBrandImageRequest(companyLogo.image.fileName));
      } else {
        dispatch(removeBrandImageRequest(imageToRemove.fileName));
      }
      setProfileToUpload(profile);
    } else {
      const pendingBusinessProfile = JSON.stringify(profile);
      profile = {
        ...profile,
        pendingBusinessProfile: pendingBusinessProfile
      }      
      dispatch(updateBusinessProfileRequest(profile));
      reset();
    }
  });

  const onFileChange = (file) => {
    if (!file) return;

    setLogoImage({ dataUri: URL.createObjectURL(file), file: file });
    setNewImageUpload(true);
  };

  const removeImage = () => {
    if (waitApprove) {
      return;
    }
    setStartUpload(false);

    if (!companyLogo.image && !logoImage) {
      return;
    } else if (
      !customerProfile.businessProfile.companyBrandings.length > 0 &&
      logoImage
    ) {
      setLogoImage(null);
      return;
    }

    setImageToRemove(logoImage);
    setLogoImage(null);
  };

  return (
    <BusinessProfileContainer>
      <Loader loaded={!showLoader} className="spinner" color="#1f343d" />

      <div>
        <h2 className="title mb-4">Business Profile</h2>
        <p className="subtitle">Company Logo</p>

        <Form>
          <Form.Group className="position-relative">
            <div className="d-flex">
              <Form.File
                type="file"
                className="company-brand-upload"
                id="companyBrandUpload"
                custom
              >
                <FontAwesomeIcon icon={faFileUpload} />
                <Form.File.Input
                  disabled={waitApprove}
                  onChange={(e) => onFileChange(e.target.files[0])}
                />

                <Loader
                  loaded={startUpload ? !companyLogo.isLoading : true}
                  className="spinner"
                  color="#1f343d"
                />
              </Form.File>

              <div className="brand-image-list d-flex ml-2">
                {logoImage && (
                  <ImageCard                    
                    image={logoImage}
                    removeImage={() => removeImage()}
                  />
                )}
              </div>
            </div>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
              <Form.Label>
                Business Name (Use your own name if you operate as a Sole
                Proprietorship)
              </Form.Label>
              <Form.Control
                  disabled={waitApprove}
                  type="text"
                  name="businessName"
                  placeholder="Enter short name"
                  isInvalid={errors.businessName}
                  ref={register({
                      required: 'Name is Required',
                  })}
              />
              {errors.businessName && (
                <Form.Control.Feedback type="invalid">
                  {errors.businessName.message}
                </Form.Control.Feedback>
              )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupName">
                <Form.Label>
                    Business Short Name
                </Form.Label>
                <Form.Control
                  disabled={waitApprove}
                  type="text"
                  name="businessShortName"
                  placeholder="Enter short name"
                  isInvalid={errors.businessShortName}
                  ref={register({
                    required: 'Name is Required',
                    pattern: {
                      value: /^[A-Z]+$/i,
                      message: "Short name is not valid"
                    }
                  })}
                  value={businessShortName}
                  onChange={(e)=>{
                    console.log(e.target.value);
                    var letters=/^[A-Za-z]+$/;
                    if (e.target.value.match(letters)) {
                      setbusinessShortName(e.target.value.toUpperCase());
                    }                    
                  }}
                />
                {errors.businessShortName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.businessShortName.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formGroupDescription">
            <Form.Label>Business Description</Form.Label>
            <Form.Control
              disabled={waitApprove}
              as="textarea"
              name="businessDescription"
              rows={3}
              placeholder="Enter description"
              isInvalid={errors.businessDescription}
              ref={register({
                required: 'Description is Required',
              })}
            />
            {errors.businessDescription && (
              <Form.Control.Feedback type="invalid">
                {errors.businessDescription.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupAddr">
                <Form.Label>Business Address</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <AutoComplete
                    disabled={waitApprove}
                    // apiKey={config.REACT_APP_GOOGLE_KEY}
                    onPlaceSelected={(place) => setAddress(place)}
                    className={`${
                      subscribe && !address && 'is-invalid'
                    } form-control`}
                    placeholder="Enter address"
                    types={['address']}
                    defaultValue={address}
                  />
                  {subscribe && !address && (
                    <Form.Control.Feedback type="invalid">
                      Address is Required
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formGroupAddressDetail">
                <Form.Label>Address Details (Suite, Unit, etc.)</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faBuilding} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    type="text"
                    name="addressDetail"
                    placeholder="Enter address details"
                    ref={register()}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email Address</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    name="emailAddress"
                    type="email"
                    placeholder="Enter email"
                    ref={register({
                      //required: 'Email is Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Email address is not valid',
                      },
                    })}
                    isInvalid={errors.emailAddress}
                  />
                  {errors.emailAddress && (
                    <Form.Control.Feedback type="invalid">
                      {errors.emailAddress.message}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formGroupPhone">
                <Form.Label>Phone Number</Form.Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Phone Number is required.' }}
                  render={({ onChange, value, name }) => (
                    <PhoneInput
                      disabled={waitApprove}
                      country={
                        customerProfile.businessProfile?.businessAddress
                          .countryCode
                      }
                      value={value}
                      inputProps={{ name }}
                      onChange={(tel) => onChange(tel)}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: errors.phoneNumber ? 'inherit' : 'none' }}
                  >
                    {errors.phoneNumber.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupWebsite">
                <Form.Label>
                  Website (ex. www.your-domain.com, Facebook page, etc)
                </Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faGlobe} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    name="website"
                    type="text"
                    placeholder="Enter website url"
                    isInvalid={errors.website}
                    ref={register({
                      required: 'Website Url is Required',
                      pattern: {
                        value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
                        message: 'Website URL is not valid',
                      },
                    })}
                  />
                  {errors.website && (
                    <Form.Control.Feedback type="invalid">
                      {errors.website.message}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formGroupServiceRange">
                <Form.Label>Service Range</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPeopleArrows} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    type="text"
                    name="serviceRange"
                    placeholder="Enter service range"
                    ref={register()}
                  />

                  <InputGroup.Append>
                    <InputGroup.Text>
                      km
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGroupTimezone">
                <Form.Label>Select Timezone</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faClock} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    as="select"
                    type="text"
                    name="timezone"
                    placeholder="Select timezone"
                    ref={register()}
                  >
                    <option></option>
                    {timezoneList.map((e, key)=> {
                      return <option key={key} value={e}>{e}</option>
                    })}
                  </Form.Control>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="formGroupCurrency">
                <Form.Label>Select Currency</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faDollarSign} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    disabled={waitApprove}
                    as="select"
                    name="currency"
                    placeholder="Select currency"
                    ref={register()}
                  >
                    <option></option>
                    <option value="CAD">CAD</option>
                    <option value="USD">USD</option>
                  </Form.Control>
                </InputGroup>
              </Form.Group>              
            </Col>  
          </Row>

          <div className="d-flex justify-content-between mt-4">
            <Button
              disabled={waitApprove}
              variant="primary"
              onClick={(e) => {
                setSubscribe(true);
                onSubmit(e);
              }}
            >
              Update
            </Button>
          </div>
        </Form>
      </div>
    </BusinessProfileContainer>
  );
};

export default BusinessProfile;
