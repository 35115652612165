import styled from '@emotion/styled';

const BillingModalContainer = styled.div`
  ul {
    list-style: none;
    padding-left: 30px;

    li {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;

      svg {
        color: ${(props) => props.theme.colors.green};
      }

      span {
        margin-left: 2rem;
      }
    }
  }

  .modal-header {
    text-align: center;
  }

  .input-group-text {
    min-width: 42px;
    justify-content: center;
  }
`;

export default BillingModalContainer;
