import styled from '@emotion/styled';

const ManageStoresContainer = styled.div`
  .store-card {
    text-align: center;

    &__hero {
      padding: 1rem 1rem 0.5rem;
      text-align: center;

      img {
        width: 4.5rem;

        @media (min-width: 768px) {
          width: 6rem;
        }
      }
    }
  }
`;

export default ManageStoresContainer;
