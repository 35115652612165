import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const profileState = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        profileState.profile && profileState.profile.businessProfileId ? (
          <Component {...props} />
        ) : (
          <Redirect to="/create-profile" />
        )
      }
    />
  );
};

export default PrivateRoute;
