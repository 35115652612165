import React from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const OrderSummary = ({ plan, annual, storeTax }) => {
  let total = annual
    ? ((plan.monthlyPrice * (100 - plan.annualDiscount)) / 100) * 12
    : plan.monthlyPrice;
  let tax = (total * storeTax.rate) / 100;

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/pricing order-summary.js' is in use");

  return (
    <div className="order-details">
      <div className="d-flex align-items-center justify-content-between">
        <p>
          Vendo {plan.name} Plan - <span>{plan.currency}$</span>
        </p>
        <p className="price">
          {(annual
            ? (plan.monthlyPrice * (100 - plan.annualDiscount)) / 100
            : plan.monthlyPrice
          ).toFixed(2)}
          {`${annual ? ' x 12' : ''}`}
        </p>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <p>{`Taxes (${storeTax.rate}%)`}</p>
        <p className="price">{tax.toFixed(2)}</p>
      </div>

      <hr />

      <div className="d-flex align-items-center justify-content-between">
        <p>
          Total (<span>{plan.currency}</span>) :
        </p>
        <p className="price">$ {(total + tax).toFixed(2)}</p>
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <p>*Paid now and reoccurs monthly.</p>
      </div>
    </div>
  );
};

export default OrderSummary;
