import { Types } from '../actions/brand-image.action';

const initialState = {
  image: null,
  isLoading: false,
  error: false,
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.UPLOAD_BRAND_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.UPLOAD_BRAND_IMAGE_SUCCESS:
      return {
        ...state,
        image: action.payload,
        isLoading: false,
      };
    case Types.UPLOAD_BRAND_IMAGE_FAILURE:
      if (action.payload) {
        return {
          ...state,
          isLoading: false,
          error: action.payload.response.status
            ? action.payload.response.status
            : action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          error: 404,
        };
      }

    case Types.REMOVE_BRAND_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.REMOVE_BRAND_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.REMOVE_BRAND_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default imageReducer;
