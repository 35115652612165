import { all } from 'redux-saga/effects';
import profileSaga from './profile.saga';
import stripeSettingSaga from './stripe-setting.saga';
import businessProfileSaga from './business-profile.saga';
import watchBrandImages from './brand-image.saga';
import storesSaga from './stores.saga';
import { watchGetPrices } from './pricing.saga';
import cardsSage from './credit-card.saga';
import billingSage from './billing-info.saga';
import invoiceSaga from './invoice.saga';

export default function* rootSaga() {
  yield all([
    ...profileSaga,
    ...stripeSettingSaga,
    ...businessProfileSaga,
    ...watchBrandImages,
    ...storesSaga,
    watchGetPrices(),
    ...cardsSage,
    ...billingSage,
    ...invoiceSaga,
  ]);
}
