import request from '../utils/http.service';

export const uploadBrandImage = async (param) => {
  let image = null;
  const formData = new FormData();
  formData.append('file', param);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await request('/images/upload', 'POST', formData, config, true);
    image = res.data;
  } catch (err) {
    image = err.data;
  }

  return image;
};

export const removeBrandImage = async (param) => {
  let image = null;

  try {
    const res = await request(`/images/${param}`, 'DELETE', null, null, true);
    image = res.data;
  } catch (err) {
    image = err.data;
  }

  return image;
};
