import {
  faHome,
  faPuzzlePiece,
  faUserTie,
  faStoreAlt,
  faCreditCard,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

const dashboardRoutes = [
  // {
  //   name: 'Home',
  //   path: '/',
  //   icon: faHome,
  // },
  {
    name: 'Manage My Stores',
    path: '/stores',
    icon: faStoreAlt,
  },
  {
    name: 'Manage Business Profile',
    path: '/business-profile',
    icon: faUserTie,
  },
  {
    name: 'Create Business Profile',
    path: '/create-profile',
    icon: faUserTie,
  },
  {
    name: 'Manage Payments',
    path: '/payments',
    icon: faCreditCard,
  },
  // {
  //   name: 'Manage Add-ons',
  //   path: '/add-ons',
  //   icon: faPuzzlePiece,
  // },
];

export default dashboardRoutes;
