import React, { useState, useEffect } from 'react';
import Loader from 'react-loader';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import PhoneInput from 'react-phone-input-2';
import AutoComplete from 'react-google-autocomplete';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import MapWithMarker from '../../../components/map-marker';
import { createStoreRequest } from '../../../redux/actions/stores.action';

import {
  getFullAddress,
  getCity,
  getCountry,
  getCountryCode,
  getState,
  getPostalCode,
  getLat,
  getLng,
  torontoPos,
} from '../../../utils/common';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const CreateStore = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [address, setAddress] = useState(null);
  const [currentPos, setCurrentPos] = useState(torontoPos);
  const [showLoader, setShowLoader] = useState(false);

  const storesState = useSelector((state) => state.stores);

  const { register, handleSubmit, errors, control, reset, formState } = useForm(
    {}
  );

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/edit-store index.js' is in use");

  useEffect(() => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition((position) =>
      setCurrentPos({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    );
  }, []);

  useEffect(() => {
    const showToast = (content) => {
      addToast(content, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    };
    setShowLoader(storesState.loading);

    if (storesState.loading || formState.submitCount === 0) return;

    showToast('Store created successfully.');

    setTimeout(() => {
      history.push('/stores');
    }, 2000);
  }, [storesState.loading]);

  const onSubmit = handleSubmit((data) => {
    if (!address) return;

    let storeData = null;

    storeData = {
      ...data,
      storeAddress: {
        streetAddress: getFullAddress(address),
        additionalDetails: data.addressDetail,
        postalCode: getPostalCode(address),
        city: getCity(address),
        province: getState(address),
        country: getCountry(address),
        countryCode: getCountryCode(address),
        latitude: getLat(address),
        longitude: getLng(address),
      },
    };

    const parsePhone = parsePhoneNumberFromString(
      data.phoneNumber,
      storeData.storeAddress.countryCode
    );

    // save phoneNumber without dialCode
    storeData = {
      ...storeData,
      phoneNumber: parsePhone.nationalNumber,
    };

    dispatch(createStoreRequest(storeData));
    reset({});
  });

  return (
    <>
      <Loader loaded={!showLoader} className="spinner" color="#1f343d" />

      <h3 className="mb-4">Create a Store</h3>
      <div className="mb-5">
        <MapWithMarker lat={currentPos.lat} lng={currentPos.lng} />
      </div>

      <Form>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formGroupAddr">
              <Form.Label>Address</Form.Label>

              <AutoComplete
                placeholder=""
                onPlaceSelected={(place) => {
                  setAddress(place);
                  setCurrentPos({
                    lat: getLat(place),
                    lng: getLng(place),
                  });
                }}
                className={`${
                  formState.submitCount > 0 && !address && 'is-invalid'
                } form-control`}
                types={['address']}
              />
              {formState.submitCount > 0 && !address && (
                <Form.Control.Feedback type="invalid">
                  Address is Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGroupAddressDetail">
              <Form.Label>Address Details (Suite, Unit, etc.)</Form.Label>
              <Form.Control type="text" name="addressDetail" ref={register()} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formGroupName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                isInvalid={errors.name}
                ref={register({
                  required: 'Name is Required',
                })}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formGroupPhone">
              <Form.Label>Phone Number</Form.Label>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                rules={{ required: 'Phone Number is required.' }}
                render={({ onChange, value, name }) => (
                  <PhoneInput
                    country={
                      storesState?.singleStore &&
                      storesState?.singleStore?.storeAddress.countryCode
                    }
                    value={value}
                    inputProps={{ name }}
                    onChange={(tel) => onChange(tel)}
                  />
                )}
              />
              {errors.phoneNumber && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: errors.phoneNumber ? 'inherit' : 'none' }}
                >
                  {errors.phoneNumber.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-3">
          <Button
            className="action-control"
            variant="primary"
            onClick={(e) => onSubmit(e)}
          >
            Create
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateStore;
