import React from 'react';
import { Button } from 'react-bootstrap';

const PricingCard = ({ data, active, annual, openDetail, ...props }) => (
  <div
    className={`pricing-card ${active && 'pricing-card--selected'} text-center`}
    {...props}
  >
    {data.isPopular && (
      <div className="ribbon ribbon-top-right">
        <span>Popular</span>
      </div>
    )}

    <div className="pricing-card__description">
      <div className="pricing-card__header">
        <h4 className="pricing-card__title">{data.name}</h4>
        <p>{data.description}</p>
      </div>

      <div className="pricing-card__price">
        <div className="d-flex justify-content-center align-content-center">
          {annual && <h3>${data.monthlyPrice} </h3>}
          <h2>
            $
            {annual
              ? (
                  (data.monthlyPrice * (100 - data.annualDiscount)) /
                  100
                ).toFixed(2)
              : data.monthlyPrice}{' '}
          </h2>
        </div>
        <span>USD / month</span>
      </div>
    </div>
    <Button variant="outline-primary" onClick={openDetail}>
      Details
    </Button>
    <div
      className={`pricing-card__radio mt-3 d-flex align-items-center justify-content-center`}
    >
      {active && <div className="circle-inner" />}
    </div>
  </div>
);

export default PricingCard;
