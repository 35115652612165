import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/business-profile.action';
import * as api from '../../api/business-profile.api';

function* getBusinessProfile() {
  try {
    const result = yield call(api.getBusinessProfile);

    if (result) {
      yield put(actions.getBusinessProfileSuccess(result));
    } else {
      yield put(actions.getBusinessProfileFailure(result));
    }
  } catch (error) {
    yield put(actions.getBusinessProfileFailure(error));
  }
}

function* createBusinessProfile(action) {
  try {
    const result = yield call(api.createBusinessProfile, action.payload);

    if (result) {
      yield put(actions.createBusinessProfileSuccess(result));
    } else {
      yield put(actions.createBusinessProfileFailure(result));
    }
  } catch (error) {
    yield put(actions.createBusinessProfileFailure(error));
  }
}

function* updateBusinessProfile(action) {
  try {
    const result = yield call(api.updateBusinessProfile, action.payload);
    if (result) {
      yield put(actions.updateBusinessProfileSuccess(result));
    } else {
      yield put(actions.updateBusinessProfileFailure(result));
    }
  } catch (error) {
    yield put(actions.updateBusinessProfileFailure(error));
  }
}

const watchBusinessProfile = [
  takeEvery(actions.Types.GET_BUSINESS_PROFILE_REQUEST, getBusinessProfile),
  takeEvery(
    actions.Types.CREATE_BUSINESS_PROFILE_REQUEST,
    createBusinessProfile
  ),
  takeEvery(
    actions.Types.UPDATE_BUSINESS_PROFILE_REQUEST,
    updateBusinessProfile
  ),
];

export default watchBusinessProfile;
