import React from 'react';
import { useDispatch } from 'react-redux';
import { Badge, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import {
  setDefaultCardRequest,
  deleteCardRequest,
} from '../../redux/actions/credit-card.action';
import { formatMonthNumber, cardLogoImage } from '../../utils/common';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const CreditCardItem = ({ card, cardsState }) => {
  const dispatch = useDispatch();

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/pricing credit-card' is in use");

  return (
    <div className="payment-cards__item d-flex align-items-center justify-content-between">
      <div className="payment-cards__image d-flex">
        <img src={cardLogoImage(card.brand)} alt="card-logo" />
      </div>

      <div className="payment-cards__method d-flex align-items-center">
        <div className="payment-cards__data">
          <div className="payment-cards__number">•••• {card.lastFour}</div>

          <div className="payment-cards__expired">
            {`Expires ${formatMonthNumber(card.expMonth)} / ${card.expYear}`}
          </div>
        </div>

        <Badge
          variant="secondary"
          className={`${
            !(card.isDefault || cardsState.cards.length === 1) && 'opacity-none'
          }`}
        >
          Default
        </Badge>
      </div>

      <div className="payment-cards__expired">
        {`Expires ${formatMonthNumber(card.expMonth)} / ${card.expYear}`}
      </div>

      <Badge
        variant="secondary"
        className={`${
          !(card.isDefault || cardsState.cards.length === 1) && 'opacity-none'
        }`}
      >
        Default
      </Badge>

      <div
        className={`payment-cards__action ${card.isDefault && 'opacity-none'}`}
      >
        <Dropdown>
          <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
            <FontAwesomeIcon icon={faEllipsisH} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => dispatch(setDefaultCardRequest(card.creditCardId))}
            >
              Make as Default
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => dispatch(deleteCardRequest(card.creditCardId))}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default CreditCardItem;
