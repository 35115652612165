import styled from '@emotion/styled';

const PlanModalContainer = styled.div`
  ul {
    list-style: none;
    padding-left: 30px;

    li {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;

      svg {
        color: ${(props) => props.theme.colors.green};
      }

      span {
        margin-left: 2rem;
      }
    }
  }

  .modal-header {
    text-align: center;
  }
`;

export default PlanModalContainer;
