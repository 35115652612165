import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { stripTrailingSlash } from '../../utils/common';
import StoreSVG from '../../assets/images/store-svg.svg';

const StoreCard = ({ store }) => {
  const location = useLocation();

  return (
    <Card className="store-card">
      <div className="store-card__hero">
        <img src={StoreSVG} alt="store-svg" />
      </div>

      <Card.Body>
        <Card.Title>{`${store.name}'s Store`}</Card.Title>

        {!store.subscription && (
          <div className="d-flex justify-content-center mb-2">
            <Link
              to={`${stripTrailingSlash(location.pathname)}/edit-store/${
                store.storeId
              }/pricing/`}
            >
              <Button variant="danger">Subscribe Now</Button>
            </Link>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <Link to={`/stores/edit-store/${store.storeId}`}>
            <Button variant="outline-primary">Manage Store</Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StoreCard;
