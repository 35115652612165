import React from 'react';
import config from '../../config/config';
import { Modal } from 'react-bootstrap';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CreateCardForm from '../credit-card';

import PaymentModalContainer from './index.style';

const stripePromise = loadStripe(config.StripePublicKey);

const PaymentModal = ({ plan, newCard, onClose, ...props }) => {
  return (
    <Modal
      {...props}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="plan-modal"
    >
      <PaymentModalContainer>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add payment method
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Elements stripe={stripePromise}>
            <CreateCardForm newCard={newCard} onClose={onClose} />
          </Elements>
        </Modal.Body>
      </PaymentModalContainer>
    </Modal>
  );
};

export default PaymentModal;
