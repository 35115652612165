import styled from '@emotion/styled';

const PaymentsContainer = styled.div`
  .payment-card {
    img {
      height: 120px;
      padding: 15px;
      width: auto;
      object-fit: contain;

      @media (min-width: 1024px) {
        height: 180px;
        padding: 20px;
      }
    }
  }
`;

export default PaymentsContainer;
