import React, { useState } from 'react';
import Loader from 'react-loader';
import { Button, Form } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { createCardRequest } from '../../redux/actions/credit-card.action';

import CreditCardFormContainer from './index.style';

const CreditCardForm = ({ newCard, onClose }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const customerProfile = useSelector((state) => state.auth.profile);
  const creditCardState = useSelector((state) => state.credit);

  const [defaultCard, setDefaultCard] = useState(newCard);

  const stripe = useStripe();
  const elements = useElements();

  const showToast = (content) => {
    addToast(content, {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createToken(cardElement);

    if (result.error) {
      showToast(result.error.message);
      onClose();
      return;
    }

    let param = {
      cardCreationToken: result.token.id,
      isDefault: defaultCard,
      customerId: customerProfile.customerId,
    };

    dispatch(createCardRequest(param));

    if (stripe && !creditCardState.loading) onClose();
  };

  return (
    <CreditCardFormContainer>
      <Form onSubmit={handleSubmit} className="create-card">
        <p>Card information</p>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />

        <Form.Group controlId="formBasicCheckbox" className="mb-2">
          <Form.Check
            type="checkbox"
            onChange={(e) => setDefaultCard(e.target.checked)}
            label="Use as default payment method"
            checked={defaultCard}
          />
        </Form.Group>

        <div className="d-flex justify-content-between">
          <Button type="submit" variant="info" disabled={!stripe}>
            Add
            <Loader
              loaded={!creditCardState.loading}
              className="spinner"
              color="#1f343d"
            />
          </Button>

          <Button variant="outline-secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Form>
    </CreditCardFormContainer>
  );
};

export default CreditCardForm;
