import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const defaultMapOptions = {
  fullscreenControl: false,
};

const MapWithMarker = ({ lat, lng }) => {
  const [mapPos, setMapPos] = useState({ lat: lat, lng: lng });

  useEffect(() => {
    setMapPos({
      lat: lat,
      lng: lng,
    });
  }, [lat, lng]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          height: '400px',
          width: '100%',
        }}
        mapContainerClassName="map-with-marker"
        zoom={13}
        center={{ lat: mapPos.lat, lng: mapPos.lng }}
        options={defaultMapOptions}
      >
        <Marker position={{ lat: mapPos.lat, lng: mapPos.lng }} />
      </GoogleMap>
    </>
  );
};

export default MapWithMarker;
