import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const HomePage = () => {
  const history = useHistory();

  const appInsights = useAppInsightsContext();

  appInsights.trackMetric("Component '/home' is in use");

  useEffect(() => {
    history.push('/stores');
  }, []);

  return <div></div>;
};

export default HomePage;
