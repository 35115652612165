export const menuList = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Manage Business Profile',
    path: '/business-profile',
  },
  {
    name: 'Manage Payments',
    path: '/payments',
  },
  {
    name: 'Manage My Stores',
    path: '/stores',
  },
  {
    name: 'Create Stores',
    path: '/stores/create-store',
  },
  {
    name: 'Manage Stores',
    path: '/stores/edit-store',
  },
  {
    name: 'Manage Subscription',
    path: '/pricing',
  },
  // {
  //   name: 'Manage Add-ons',
  //   path: '/add-ons',
  // },
];
