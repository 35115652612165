import jwt_decode from 'jwt-decode';

import VisaSVG from '../assets/cards/visa.svg';
import MastercardSVG from '../assets/cards/mastercard.svg';
import JcbSVG from '../assets/cards/jcb.svg';
import AmericanExpressSVG from '../assets/cards/american-express.svg';

export const torontoPos = {
  lat: 43.70011,
  lng: -79.4163,
};

export const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

export const isTokenExpired = (token) => {
  if (!token) return false;

  try {
    jwt_decode(token);
  } catch (error) {
    return false;
  }

  let decoded = jwt_decode(token);
  if (decoded.exp < Date.now() / 1000) {
    return false;
  }

  return true;
};

// Get detailed information from Google Address

export const getStressAddress = (address) => {
  let addr = address.address_components;

  return `${
    addr?.filter((el) => el.types[0] === 'street_number').length > 0
      ? addr?.filter((el) => el.types[0] === 'street_number')[0]?.long_name
      : ''
  }${addr?.filter((el) => el.types[0] === 'route')[0]?.long_name}`;
};

export const getCity = (address) => {
  let addr = address.address_components;
  return addr?.filter((el) => el.types[0] === 'locality')[0]?.long_name;
};

export const getArea = (address) => {
  let addr = address.address_components;
  return addr?.filter((el) => el.types[0] === 'sublocality_level_1')[0]
    ?.long_name;
};

export const getState = (address) => {
  let addr = address.address_components;
  return addr?.filter((el) => el.types[0] === 'administrative_area_level_1')[0]
    ?.long_name;
};

export const getCountry = (address) => {
  let addr = address.address_components;
  return addr?.filter((el) => el.types[0] === 'country')[0]?.long_name;
};

export const getCountryCode = (address) => {
  let addr = address.address_components;
  return addr?.filter((el) => el.types[0] === 'country')[0]?.short_name;
};

export const getPostalCode = (address) => {
  let addr = address.address_components;
  return addr?.filter((el) => el.types[0] === 'postal_code')[0]?.short_name;
};

export const getLat = (address) => address.geometry.location.lat();

export const getLng = (address) => address.geometry.location.lng();

export const getFullAddress = (address) => address.formatted_address;

export const stripTrailingSlash = (str) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const formatMonthNumber = (month) => {
  let formattedNumber = ('0' + month).slice(-2);
  return formattedNumber;
};

export const cardLogoImage = (cardType) => {
  let logoImg = null;

  switch (cardType.toLowerCase()) {
    case 'mastercard':
      logoImg = MastercardSVG;
      break;
    case 'visa':
      logoImg = VisaSVG;
      break;
    case 'jcb':
      logoImg = JcbSVG;
      break;
    case 'american express':
      logoImg = AmericanExpressSVG;
      break;
    default:
      break;
  }

  return logoImg;
};
