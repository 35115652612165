import React, { useEffect, useState } from 'react';
import Loader from 'react-loader';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import AutoComplete from 'react-google-autocomplete';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faMapMarkerAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import {
  getFullAddress,
  getCity,
  getCountry,
  getCountryCode,
  getState,
  getPostalCode,
  getLat,
  getLng,
} from '../../utils/common';

import {
  getBillInfoRequest,
  updateBillInfoRequest,
} from '../../redux/actions/billing-info.action';

import BillingModalContainer from './index.style';

const BillingModal = ({ onClose, ...props }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState('');
  const { control, register, handleSubmit, errors, reset } = useForm({});

  const billAddrState = useSelector((state) => state.billing);

  useEffect(() => {
    dispatch(getBillInfoRequest());
  }, []);

  useEffect(() => {
    if (!billAddrState.billInfo) return;
    setAddress(billAddrState.billInfo?.billingAddress?.streetAddress);

    const parsePhone = parsePhoneNumberFromString(
      billAddrState.billInfo.phoneNumber,
      billAddrState.billInfo.billingAddress.countryCode
    );

    reset({
      addressDetail: billAddrState.billInfo.billingAddress.additionalDetails,
      emailAddress: billAddrState.billInfo.email,
      phoneNumber: parsePhone.number,
    });
  }, [billAddrState]);

  const onSubmit = handleSubmit((data) => {
    if (!address) return;

    let billAddr = {};

    if (billAddrState.billInfo?.billingAddress?.streetAddress === address) {
      billAddr = {
        ...billAddrState.billInfo.billingAddress,
      };
    } else {
      billAddr = {
        streetAddress: getFullAddress(address),
        additionalDetails: data.addressDetail,
        city: getCity(address),
        province: getState(address),
        country: getCountry(address),
        countryCode: getCountryCode(address),
        postalCode: getPostalCode(address),
        latitude: getLat(address),
        longitude: getLng(address),
      };
    }

    const parsePhone = parsePhoneNumberFromString(
      data.phoneNumber,
      billAddr.countryCode
    );

    let billingInfo = {
      email: data.emailAddress,
      billingAddress: billAddr,
      phoneNumber: parsePhone.nationalNumber,
    };

    dispatch(updateBillInfoRequest(billingInfo));

    onClose();
  });

  return (
    <Modal
      {...props}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="billing-modal"
    >
      <BillingModalContainer>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Billing information
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loader
            loaded={billAddrState ? !billAddrState.loading : false}
            className="spinner"
          />

          <Form>
            <Row>
              <Col md={12}>
                <Form.Group controlId="formGroupEmail">
                  <Form.Label>Email Address</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      name="emailAddress"
                      type="email"
                      placeholder="Enter email"
                      ref={register({
                        required: 'Email is Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Email address is not valid',
                        },
                      })}
                      isInvalid={errors.emailAddress}
                    />
                    {errors.emailAddress && (
                      <Form.Control.Feedback type="invalid">
                        {errors.emailAddress.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group controlId="formGroupPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Phone Number is required.' }}
                    render={({ onChange, value, name }) => (
                      <PhoneInput
                        value={value}
                        inputProps={{ name }}
                        onChange={(tel) => onChange(tel)}
                      />
                    )}
                  />
                  {errors.phoneNumber && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        display: errors.phoneNumber ? 'inherit' : 'none',
                      }}
                    >
                      {errors.phoneNumber.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <Form.Group controlId="formGroupAddr">
                  <Form.Label>Billing Address</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <AutoComplete
                      onPlaceSelected={(place) => setAddress(place)}
                      className={'form-control'}
                      placeholder=""
                      types={['address']}
                      defaultValue={address}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col xl={12}>
                <Form.Group controlId="formGroupAddressDetail">
                  <Form.Label>Address Details (Suite, Unit, etc.)</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBuilding} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      type="text"
                      name="addressDetail"
                      placeholder=""
                      ref={register()}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-between">
              <Button onClick={(e) => onSubmit(e)}>Save</Button>

              <Button variant="outline-secondary" onClick={onClose}>
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </BillingModalContainer>
    </Modal>
  );
};

export default BillingModal;
